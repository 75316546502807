var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.text,
          expression: "text",
        },
      ],
      staticClass: "vs-inputx vs-input--input normal",
      attrs: { id: "inputCity", placeholder: "Ciudad", list: "my-list-id" },
      domProps: { value: _vm.text },
      on: {
        input: function ($event) {
          if ($event.target.composing) {
            return
          }
          _vm.text = $event.target.value
        },
      },
    }),
    _c(
      "datalist",
      { attrs: { id: "my-list-id" } },
      _vm._l(_vm.MyItems, function (item) {
        return _c("option", { key: item.id, attrs: { id: item.id } }, [
          _vm._v(_vm._s(item.name || item.label)),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }