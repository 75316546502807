var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mx-aut" }, [
    _c(
      "div",
      { staticClass: "vx-card__collapsible-content vs-con-loading__container" },
      [
        _c(
          "form-wizard",
          {
            ref: "wizard",
            staticStyle: { height: "970px" },
            attrs: {
              color: "rgba(var(--vs-primary), 1)",
              errorColor: "rgba(var(--vs-danger), 1)",
              finishButtonText: "Finalizar",
              backButtonText: "Anterior",
              nextButtonText: "Siguiente",
            },
            on: { "on-complete": _vm.createProject, "on-change": _vm.scrollUp },
          },
          [
            _c(
              "h2",
              {
                staticClass: "custom-title",
                attrs: { slot: "title" },
                slot: "title",
              },
              [_vm._v("Crear proceso")]
            ),
            _c(
              "h3",
              {
                staticClass: "custom-title subtitle",
                attrs: { slot: "title" },
                slot: "title",
              },
              [_vm._v("Por favor llena todos los campos")]
            ),
            _c(
              "tab-content",
              {
                staticClass: "mb-5",
                attrs: {
                  title: "Información del candidato",
                  icon: "feather icon-user",
                  "before-change": _vm.validateStep1,
                },
              },
              [
                _c(
                  "form",
                  { attrs: { autocomplete: "off", "data-vv-scope": "step-1" } },
                  [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-5" },
                        [
                          _c(
                            "label",
                            { staticClass: "form-labels", attrs: { for: "" } },
                            [_vm._v("Nombres")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:50|required",
                                expression: "'max:50|required'",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              id: "firstNameCandidate",
                              name: "project.first_name",
                              maxlength: "50",
                            },
                            on: { keypress: _vm.regExpressionText },
                            model: {
                              value: _vm.project.first_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.project, "first_name", $$v)
                              },
                              expression: "project.first_name",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("project.first_name"),
                                  expression:
                                    "errors.has('project.first_name')",
                                },
                              ],
                              staticClass: "text-danger",
                            },
                            [_vm._v("Requerido")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-5" },
                        [
                          _c(
                            "label",
                            { staticClass: "form-labels", attrs: { for: "" } },
                            [_vm._v("Apellidos")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:50|required",
                                expression: "'max:50|required'",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              id: "lastNameCandidate",
                              name: "project.last_name",
                              maxlength: "50",
                            },
                            on: { keypress: _vm.regExpressionText },
                            model: {
                              value: _vm.project.last_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.project, "last_name", $$v)
                              },
                              expression: "project.last_name",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("project.last_name"),
                                  expression: "errors.has('project.last_name')",
                                },
                              ],
                              staticClass: "text-danger",
                            },
                            [_vm._v("Requerido")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-6" },
                        [
                          _c(
                            "label",
                            { staticClass: "form-labels", attrs: { for: "" } },
                            [_vm._v("Email")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|email",
                                expression: "'required|email'",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              id: "emailCandidate",
                              name: "project.email_1",
                            },
                            model: {
                              value: _vm.project.email_1,
                              callback: function ($$v) {
                                _vm.$set(_vm.project, "email_1", $$v)
                              },
                              expression: "project.email_1",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("project.email_1"),
                                  expression: "errors.has('project.email_1')",
                                },
                              ],
                              staticClass: "text-danger",
                            },
                            [_vm._v("Requerido")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-6" },
                        [
                          _c(
                            "label",
                            { staticClass: "form-labels", attrs: { for: "" } },
                            [_vm._v("Celular")]
                          ),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "min:10|required|numeric",
                                expression: "'min:10|required|numeric'",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              id: "phoneCandidate",
                              name: "project.telephone_1",
                              maxlength: "10",
                            },
                            model: {
                              value: _vm.project.telephone_1,
                              callback: function ($$v) {
                                _vm.$set(_vm.project, "telephone_1", $$v)
                              },
                              expression: "project.telephone_1",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("project.telephone_1"),
                                  expression:
                                    "errors.has('project.telephone_1')",
                                },
                              ],
                              staticClass: "text-danger",
                            },
                            [
                              _vm._v(
                                "Requerido (solo caracteres númericos de 10 dígitos)"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col md:w-1/2 w-full mt-8",
                          staticStyle: { "-webkit-margin-before": "1.4%" },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "vs-select--label form-labels",
                              attrs: { for: "" },
                            },
                            [_vm._v("Tipo de documento")]
                          ),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "w-full select-large mt-5",
                            staticStyle: {
                              "-webkit-margin-before": "0% !important",
                            },
                            attrs: {
                              options: _vm.idTypes,
                              reduce: function (city) {
                                return city.id
                              },
                              placeholder: "Seleccione una opción",
                              label: "label",
                              id: "project.person_id_type",
                              name: "project.person_id_type",
                            },
                            model: {
                              value: _vm.project.person_id_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.project, "person_id_type", $$v)
                              },
                              expression: "project.person_id_type",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has(
                                    "project.person_id_type"
                                  ),
                                  expression:
                                    "errors.has('project.person_id_type')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v("Requerido")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/2 w-full mt-8" },
                        [
                          _c(
                            "label",
                            { staticClass: "form-labels", attrs: { for: "" } },
                            [_vm._v("Número de documento")]
                          ),
                          _vm.project.person_id_type > 2
                            ? _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "min:5|required|alpha_num",
                                    expression: "'min:5|required|alpha_num'",
                                  },
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  id: "project.person_id",
                                  name: "project.person_id",
                                },
                                on: {
                                  keypress: _vm.onlyText,
                                  paste: _vm.onPaste,
                                },
                                model: {
                                  value: _vm.project.person_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.project, "person_id", $$v)
                                  },
                                  expression: "project.person_id",
                                },
                              })
                            : _c("vs-input", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "min:5|required|numeric",
                                    expression: "'min:5|required|numeric'",
                                  },
                                ],
                                staticClass: "w-full",
                                attrs: {
                                  id: "project.person_id",
                                  name: "project.person_id",
                                },
                                on: {
                                  keypress: _vm.onlyText,
                                  paste: _vm.onPaste,
                                },
                                model: {
                                  value: _vm.project.person_id,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.project, "person_id", $$v)
                                  },
                                  expression: "project.person_id",
                                },
                              }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("project.person_id"),
                                  expression: "errors.has('project.person_id')",
                                },
                              ],
                              staticClass: "text-danger",
                            },
                            [_vm._v("Requerido")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "vx-row mt-3 pb-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "vx-col md:w-1/3 w-full",
                          staticStyle: { "-webkit-margin-before": "1.6%" },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "vs-select--label form-labels",
                              attrs: { for: "" },
                            },
                            [_vm._v("Nivel educativo")]
                          ),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "w-full select-large mt-5",
                            staticStyle: {
                              "-webkit-margin-before": "0% !important",
                            },
                            attrs: {
                              id: "selectCountry",
                              options: _vm.educationLevels,
                              reduce: function (level) {
                                return level.id
                              },
                              placeholder: "Nivel educativo",
                              label: "label",
                              name: "project.education_level",
                            },
                            model: {
                              value: _vm.project.education_level,
                              callback: function ($$v) {
                                _vm.$set(_vm.project, "education_level", $$v)
                              },
                              expression: "project.education_level",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has(
                                    "project.education_level"
                                  ),
                                  expression:
                                    "errors.has('project.education_level')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v("Requerido")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col md:w-1/3 w-full",
                          staticStyle: { "-webkit-margin-before": "1.6%" },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "vs-select--label form-labels",
                              attrs: { for: "" },
                            },
                            [_vm._v("País")]
                          ),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "w-full select-large mt-5",
                            staticStyle: {
                              "-webkit-margin-before": "0% !important",
                            },
                            attrs: {
                              id: "selectCountry",
                              options: _vm.countries,
                              reduce: function (country) {
                                return country.id
                              },
                              placeholder: "Seleccionar país",
                              label: "name",
                              name: "country_id",
                            },
                            model: {
                              value: _vm.country_id,
                              callback: function ($$v) {
                                _vm.country_id = $$v
                              },
                              expression: "country_id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col md:w-1/3 w-full",
                          staticStyle: { "-webkit-margin-before": "1.6%" },
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "vs-select--label form-labels",
                              attrs: { for: "" },
                            },
                            [_vm._v("Ciudad")]
                          ),
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "w-full select-large mt-5",
                            staticStyle: {
                              "-webkit-margin-before": "0% !important",
                            },
                            attrs: {
                              id: "selectCity",
                              options: _vm.cities,
                              reduce: function (city) {
                                return city.id
                              },
                              placeholder: "Seleccionar ciudad",
                              label: "name",
                              name: "project.city_id",
                            },
                            model: {
                              value: _vm.project.city_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.project, "city_id", $$v)
                              },
                              expression: "project.city_id",
                            },
                          }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.errors.has("project.city_id"),
                                  expression: "errors.has('project.city_id')",
                                },
                              ],
                              staticClass: "text-danger text-sm",
                            },
                            [_vm._v("Requerido")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "tab-content",
              {
                staticClass: "mb-5",
                attrs: {
                  title: "Información del proceso",
                  icon: "feather icon-clipboard",
                  "before-change": _vm.validateStep2,
                },
              },
              [
                _c("form", { attrs: { "data-vv-scope": "step-2" } }, [
                  _c(
                    "div",
                    { staticClass: "vx-row" },
                    [
                      _c("products", {
                        attrs: {
                          contract_id: _vm.contract_id,
                          productsProject: _vm.productsProject,
                        },
                        on: {
                          "update:contract_id": function ($event) {
                            _vm.contract_id = $event
                          },
                          "update:productsProject": function ($event) {
                            _vm.productsProject = $event
                          },
                          "update:products-project": function ($event) {
                            _vm.productsProject = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.productsProject != null &&
                  [13, 6, 12, 15, 18].includes(_vm.productsProject[0])
                    ? _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/1 w-full md:mt-8" },
                          [
                            _c("div", { staticClass: "demo-alignment" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "flex",
                                  staticStyle: { "flex-direction": "column" },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "18px" } },
                                    [
                                      _vm._v(
                                        "Selecciona el formato de la visita"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-radio",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      attrs: {
                                        "vs-value": false,
                                        "vs-name": "is-virtual",
                                        name: "project.projectIsVirtual",
                                      },
                                      model: {
                                        value: _vm.projectIsVirtual,
                                        callback: function ($$v) {
                                          _vm.projectIsVirtual = $$v
                                        },
                                        expression: "projectIsVirtual",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Hacer la visita presencial\n                  "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "vs-radio",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      attrs: {
                                        "vs-value": true,
                                        "vs-name": "is-virtual",
                                        name: "project.projectIsVirtual",
                                      },
                                      model: {
                                        value: _vm.projectIsVirtual,
                                        callback: function ($$v) {
                                          _vm.projectIsVirtual = $$v
                                        },
                                        expression: "projectIsVirtual",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Hacer la visita virtual\n                  "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "project.projectIsVirtual"
                                          ),
                                          expression:
                                            "errors.has('project.projectIsVirtual')",
                                        },
                                      ],
                                      staticClass: "text-danger",
                                    },
                                    [_vm._v("Requerido")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "vx-row" }, [_c("hr")]),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/1 w-full mt-5" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "w-full",
                          attrs: {
                            id: "chargeToApply",
                            label: "Cargo aspirante / colaborador",
                            name: "project.current_activity",
                          },
                          on: { keypress: _vm.regExpressionTextNumbers },
                          model: {
                            value: _vm.project.current_activity,
                            callback: function ($$v) {
                              _vm.$set(_vm.project, "current_activity", $$v)
                            },
                            expression: "project.current_activity",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has(
                                  "project.current_activity"
                                ),
                                expression:
                                  "errors.has('project.current_activity')",
                              },
                            ],
                            staticClass: "text-danger text-sm",
                          },
                          [_vm._v("Requerido")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.businessUnits && _vm.businessUnits.length > 0
                    ? _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col md:w-1/1 w-full mt-5" },
                          [
                            _c(
                              "vs-select",
                              {
                                staticClass: "w-full select-large mt-5",
                                attrs: {
                                  id: "selectBusinessUnit",
                                  label: "Empresa contratante",
                                  name: "businessUnit",
                                },
                                model: {
                                  value: _vm.businessUnit,
                                  callback: function ($$v) {
                                    _vm.businessUnit = $$v
                                  },
                                  expression: "businessUnit",
                                },
                              },
                              _vm._l(_vm.businessUnits, function (unit, index) {
                                return _c("vs-select-item", {
                                  key: index,
                                  staticClass: "w-full",
                                  attrs: { value: unit.id, text: unit.name },
                                })
                              }),
                              1
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("businessUnit"),
                                    expression: "errors.has('businessUnit')",
                                  },
                                ],
                                staticClass: "text-danger text-sm",
                              },
                              [_vm._v("Requerido")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/1 w-full mt-5" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full",
                          attrs: {
                            id: "billingCenter",
                            label: "Centro de costo",
                            name: "project.billing_center",
                          },
                          on: { keypress: _vm.regExpressionTextNumbers },
                          model: {
                            value: _vm.project.billing_center,
                            callback: function ($$v) {
                              _vm.$set(_vm.project, "billing_center", $$v)
                            },
                            expression: "project.billing_center",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("project.billing_center"),
                                expression:
                                  "errors.has('project.billing_center')",
                              },
                            ],
                            staticClass: "text-danger text-sm",
                          },
                          [_vm._v("Requerido")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "tab-content",
              {
                staticClass: "mb-5",
                attrs: {
                  title: "Información Adicional",
                  icon: "feather icon-briefcase",
                  "before-change": _vm.validateStep3,
                },
              },
              [
                _c("form", { attrs: { "data-vv-scope": "step-3" } }, [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col md:w-1/1 w-full md:mt-8" },
                      [
                        _vm.productsProject == 4
                          ? _c(
                              "div",
                              { staticClass: "vx-row" },
                              [
                                _c("PoligraphyCards", {
                                  attrs: { formFailed: _vm.formFailed },
                                  on: {
                                    "on-form-failure": _vm.handleFormFailure,
                                    "update-poligraph-date-second":
                                      _vm.handlePoligraphDateSecondUpdate,
                                    "update-poligraph-date":
                                      _vm.handlePoligraphDateUpdate,
                                    "update-poligraph-reason":
                                      _vm.handlePoligraphReasonUpdate,
                                    "update-poligraphy-type":
                                      _vm.handlePoligraphyTypeUpdate,
                                    "update-poligraph-virtual":
                                      _vm.handlePoligraphVirtualUpdate,
                                    "update-form-validity":
                                      _vm.handleFormValidityUpdate,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("vs-divider", {
                          staticStyle: { "padding-top": "16px" },
                        }),
                        _c("div", { staticClass: "demo-alignment ml-2" }, [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c(
                                "vs-checkbox",
                                {
                                  attrs: { id: "allowanceAuthorization" },
                                  model: {
                                    value: _vm.project.allowance_authorization,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.project,
                                        "allowance_authorization",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "project.allowance_authorization",
                                  },
                                },
                                [_vm._v("Autorización de viáticos")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "vx-row mb-6",
                      staticStyle: { "margin-left": "0.5px" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "vx-col md:w-1/1 w-full mt-5" },
                        [
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              id: "travelAllownce",
                              label: "Cantidad de viáticos",
                              name: "travel_allowance",
                              type: "text",
                            },
                            on: {
                              keypress: _vm.onlyNumbers,
                              paste: _vm.onlyNumbersOnPaste,
                            },
                            model: {
                              value: _vm.project.travel_allowance,
                              callback: function ($$v) {
                                _vm.$set(_vm.project, "travel_allowance", $$v)
                              },
                              expression: "project.travel_allowance",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.productsProject !== null &&
                  _vm.productsProject[0] !== 3 &&
                  _vm.hideDatacredit === false
                    ? _c("div", { staticClass: "vx-row ml-2 mb-6" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "vx-col md:w-1/1 w-full md:mt-8 pb-3 pt-0 title-bar",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "demo-alignment",
                                staticStyle: {
                                  "justify-content": "space-between",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex ml" },
                                  [
                                    _c("vs-checkbox", {
                                      model: {
                                        value: _vm.project.credit_validation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.project,
                                            "credit_validation",
                                            $$v
                                          )
                                        },
                                        expression: "project.credit_validation",
                                      },
                                    }),
                                    _c("label", [
                                      _vm._v(
                                        "¿Quieres incluir la verificación en centrales de riesgo (Datacrédito)?"
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c("div", [
                                  _c("img", {
                                    attrs: {
                                      src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Wallet.png",
                                      alt: "",
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "vx-row pl-1" }, [
                    _c(
                      "div",
                      {
                        staticClass: "vx-col md:w-1/1 w-full",
                        staticStyle: { "margin-top": "21px" },
                      },
                      [
                        _c(
                          "label",
                          {
                            staticStyle: { "font-size": "15px" },
                            attrs: { for: "" },
                          },
                          [
                            _vm._v(
                              "¿Tienes alguna instrucción u observación especial sobre el proceso?"
                            ),
                          ]
                        ),
                        _c("vs-input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "max:2500",
                              expression: "'max:2500'",
                            },
                          ],
                          staticClass: "w-full mt-5",
                          attrs: {
                            name: "project.observations",
                            maxlength: "2500",
                          },
                          on: { keypress: _vm.regExpressionTextNumbers },
                          model: {
                            value: _vm.project.observations,
                            callback: function ($$v) {
                              _vm.$set(_vm.project, "observations", $$v)
                            },
                            expression: "project.observations",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has(
                                  "project.observations.length > 2500"
                                ),
                                expression:
                                  "errors.has('project.observations.length > 2500')",
                              },
                            ],
                            staticClass: "text-danger",
                          },
                          [_vm._v("Requerido")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            ),
            _c(
              "tab-content",
              {
                staticClass: "mb-5",
                attrs: {
                  title: "Documentos del candidato",
                  icon: "feather icon-file",
                  "before-change": _vm.validateStep3,
                },
              },
              [
                _c("form", { attrs: { "data-vv-scope": "step-4" } }, [
                  _c("div", { staticClass: "files-candiate" }, [
                    _c(
                      "div",
                      { staticClass: "list-documents" },
                      [
                        _c(
                          "vs-list",
                          [
                            _c("vs-list-header", {
                              attrs: {
                                icon: "format_list_numbered",
                                title:
                                  "Documentos necesarios para iniciar el proceso",
                              },
                            }),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.typeFiles,
                                function (type, typeFileIndex) {
                                  return _c("div", { key: type.id }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "accordion-header",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleAccordion(
                                              typeFileIndex,
                                              false
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "item-document" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "column" },
                                              [
                                                _c(
                                                  "vs-button",
                                                  {
                                                    staticClass:
                                                      "color-purple icon-add text-size",
                                                    attrs: {
                                                      type: "flat",
                                                      disabled:
                                                        _vm.allowsNewFile(
                                                          type.type_file
                                                        ),
                                                    },
                                                    on: {
                                                      click: [
                                                        function ($event) {
                                                          $event.preventDefault()
                                                          _vm.assignFile(
                                                            type.type_file
                                                          ),
                                                            _vm.toggleAccordion(
                                                              typeFileIndex,
                                                              true
                                                            )
                                                        },
                                                        function ($event) {
                                                          $event.stopPropagation()
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "material-icons item-icon",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "drive_folder_upload"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(type.label) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "column-2" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "max-upload" },
                                                  [
                                                    _vm._v(
                                                      "Máximo " +
                                                        _vm._s(
                                                          type.maxUploads
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          type.maxUploads == 1
                                                            ? "archivo"
                                                            : "archivos"
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "vs-button",
                                              {
                                                staticClass: "icon-add",
                                                attrs: { type: "transparent" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "material-icons",
                                                    attrs: {
                                                      id:
                                                        "chevron" +
                                                        typeFileIndex,
                                                    },
                                                  },
                                                  [_vm._v("expand_more")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "accordion-content",
                                            attrs: {
                                              id: "content" + typeFileIndex,
                                            },
                                          },
                                          [
                                            _vm._l(_vm.files, function (file) {
                                              return _c(
                                                "div",
                                                { key: file.input_id },
                                                [
                                                  type.type_file === file.id
                                                    ? _c("span", [
                                                        file.content !== null
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "item-document",
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "material-icons item-icon color-green",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "check"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  file
                                                                    .name_upload
                                                                    .length < 30
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-size color-green",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                file.name_upload
                                                                              )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "text-size color-green",
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                file.name_upload.substring(
                                                                                  0,
                                                                                  40
                                                                                ) +
                                                                                  "..."
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ]),
                                                                _c(
                                                                  "vs-button",
                                                                  {
                                                                    staticClass:
                                                                      "item-icon",
                                                                    attrs: {
                                                                      color:
                                                                        "danger",
                                                                      type: "flat",
                                                                    },
                                                                    on: {
                                                                      click: [
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.clearInputsFile(
                                                                            file
                                                                          )
                                                                        },
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                        },
                                                                      ],
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "material-icons item-icon",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "clear"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              )
                                            }),
                                            _vm.noFilesAdded(typeFileIndex)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-document",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "no-added-files",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "material-icons item-icon",
                                                          },
                                                          [_vm._v("info")]
                                                        ),
                                                        _vm._v(
                                                          " No se han agregado archivos"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                              0
                            ),
                          ],
                          1
                        ),
                        _vm._l(_vm.files, function (file) {
                          return _c("div", { key: file.input_id }, [
                            _c("input", {
                              ref: "inputFile" + file.input_id,
                              refInFor: true,
                              staticStyle: { display: "none" },
                              attrs: {
                                type: "file",
                                id: "inputFile" + file.input_id,
                                accept:
                                  "image/png, image/jpg, image/jpeg, application/pdf",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.preChargeFiles(file.input_id)
                                },
                              },
                            }),
                          ])
                        }),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.non_valid_message,
                                expression: "this.non_valid_message",
                              },
                            ],
                            staticClass: "extension-error",
                          },
                          [
                            _c("p", { staticClass: "extension-message" }, [
                              _vm._v(
                                "Solo se permite subir formatos en png, jpg, jpeg y pdf."
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: this.non_valid_size,
                                expression: "this.non_valid_size",
                              },
                            ],
                            staticClass: "extension-error",
                          },
                          [
                            _c("p", { staticClass: "extension-message" }, [
                              _vm._v(
                                "El tamaño del archivo debe ser menor a 2 MB."
                              ),
                            ]),
                          ]
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            ),
            _vm.showLoader
              ? _c(
                  "div",
                  { staticClass: "loader-create" },
                  [_c("Loader", { attrs: { show: "true" } })],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }