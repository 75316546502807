
<template>

  <div>
    <input class="vs-inputx vs-input--input normal" v-model="text" id="inputCity" placeholder="Ciudad" list="my-list-id"/>
    <datalist id="my-list-id">
      <option v-for="item in MyItems" :id="item.id" :key="item.id">{{ item.name || item.label }}</option>
    </datalist>
  </div>
</template>

<script>
import Cities from '../../mixins/cities'
export default {
  props:['items'],
  name: 'AutocompleteLocal',
  data() {
    return{
      text:''
    }
  },
  methods:{

  },
  computed:{
    MyItems:{
      get() {
        return this.items
      },
      set(val){
        this.items = val
      }
    },
    MyModel:{
      get() {
        return this.model
      },
      set(val){
        this.$emit('update:model',val)
      }
    }
  },
  watch: {
      text(val){
        if(val.length > 0) {
          console.log(val.toUpperCase())
        const value = this.MyItems.filter(item => item.name.toUpperCase() == val.toUpperCase())
        if(value[0] != undefined){
          console.log('El id es:',value)
          this.$emit('update-city',value[0].id)
          console.log(this.MyModel)
        }else{
          console.log(value)
        }
			}
      }
    }
}
</script>

<style>

</style>