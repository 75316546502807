var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "margin-top": "40px" } }, [
    _c(
      "table",
      {
        staticClass: "vs-table vs-table--tbody-table",
        attrs: { cellspacing: "0", cellpadding: "0" },
      },
      [
        _vm._m(0),
        _c("div", { staticStyle: { height: "20px" } }),
        _vm._l(_vm.projectCategories, function (category) {
          return _c("tr", { key: category.id }, [
            _c(
              "td",
              { staticStyle: { width: "100%" }, attrs: { colspan: "3" } },
              [
                category.id == 0
                  ? _c(
                      "div",
                      {
                        staticStyle: { "border-radius": "8px" },
                        attrs: { "icon-arrow": "expand_more" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "category-style",
                            staticStyle: {
                              padding: "15px",
                              "font-size": "18px",
                            },
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [_vm._v(_vm._s(category.name))]
                        ),
                        _vm._l(_vm.projectTypes, function (product) {
                          return product.category_ids.includes(category.id)
                            ? _c(
                                "tr",
                                {
                                  key: product.id,
                                  staticClass:
                                    "tr-values vs-table--tr tr-table-state-null",
                                },
                                [
                                  _c("div", {
                                    staticStyle: { height: "20px" },
                                  }),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "mr-3 ml-1",
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "100%",
                                      },
                                    },
                                    [
                                      _c(
                                        "vs-row",
                                        [
                                          _c(
                                            "vs-col",
                                            {
                                              attrs: {
                                                "vs-type": "flex",
                                                "vs-justify": "center",
                                                "vs-align": "center",
                                                "vs-w": "12",
                                              },
                                            },
                                            [
                                              _c("vs-checkbox", {
                                                attrs: {
                                                  id:
                                                    "product" +
                                                    product.id +
                                                    category.id,
                                                  "vs-value": product.id,
                                                },
                                                model: {
                                                  value:
                                                    _vm.traditionalProductsSelected,
                                                  callback: function ($$v) {
                                                    _vm.traditionalProductsSelected =
                                                      $$v
                                                  },
                                                  expression:
                                                    "traditionalProductsSelected",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "td vs-table--td product-gw",
                                      staticStyle: { width: "425px" },
                                    },
                                    [
                                      _c("span", { staticClass: "ml-8" }, [
                                        _c("span", { staticClass: "ml-8" }, [
                                          _vm._v(_vm._s(product.name)),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "td vs-table--td product-gw",
                                    },
                                    [
                                      _c("span", [
                                        _c("span", [
                                          _vm._v(_vm._s(product.category)),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "td vs-table--td produc-description-gw",
                                    },
                                    [
                                      _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "flex items-center px-2 py-1 rounded",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(product.description) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e()
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "vs-collapse",
                  [
                    category.id > 0
                      ? _c(
                          "vs-collapse-item",
                          {
                            staticStyle: {
                              "border-radius": "8px",
                              background: "#FBFAFC",
                            },
                            attrs: { "icon-arrow": "expand_more" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "category-style",
                                staticStyle: {
                                  "font-size": "18px",
                                  padding: "18px",
                                },
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [_vm._v(_vm._s(category.name))]
                            ),
                            _vm._l(_vm.projectTypes, function (product) {
                              return product.category_ids.includes(category.id)
                                ? _c(
                                    "tr",
                                    {
                                      key: product.id,
                                      staticClass:
                                        "tr-values vs-table--tr tr-table-state-null",
                                    },
                                    [
                                      _c("div", {
                                        staticStyle: { height: "20px" },
                                      }),
                                      _c(
                                        "td",
                                        {
                                          staticClass: "mr-3 ml-1",
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                            height: "100%",
                                          },
                                        },
                                        [
                                          _c(
                                            "vs-row",
                                            [
                                              _c(
                                                "vs-col",
                                                {
                                                  attrs: {
                                                    "vs-type": "flex",
                                                    "vs-justify": "center",
                                                    "vs-align": "center",
                                                    "vs-w": "12",
                                                  },
                                                },
                                                [
                                                  _c("vs-checkbox", {
                                                    attrs: {
                                                      id:
                                                        "product" +
                                                        product.id +
                                                        category.id,
                                                      "vs-value": product.id,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.traditionalProductsSelected,
                                                      callback: function ($$v) {
                                                        _vm.traditionalProductsSelected =
                                                          $$v
                                                      },
                                                      expression:
                                                        "traditionalProductsSelected",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "td vs-table--td product-gw",
                                          staticStyle: { width: "380px" },
                                        },
                                        [
                                          _c("span", { staticClass: "ml-8" }, [
                                            _c(
                                              "span",
                                              { staticClass: "product-name" },
                                              [
                                                _c("p", [
                                                  _vm._v(_vm._s(product.name)),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "td vs-table--td product-gw",
                                        },
                                        [
                                          _c("span", [
                                            _c("span", [
                                              _vm._v(_vm._s(product.category)),
                                            ]),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "td vs-table--td produc-description-gw",
                                        },
                                        [
                                          _c("span", [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flex items-center px-2 py-1 rounded",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      product.description
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e()
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "description-products" }, [
      _c("tr", [
        _c(
          "th",
          {
            staticStyle: {
              width: "13% !important",
              "border-radius": "8px 0% 0% 8px",
            },
            attrs: { colspan: "1", rowspan: "1" },
          },
          [
            _c("div", { staticClass: "vs-table-text pl-6" }, [
              _vm._v("Selecciona una opción"),
            ]),
          ]
        ),
        _c(
          "th",
          {
            staticStyle: { width: "35%" },
            attrs: { colspan: "1", rowspan: "1" },
          },
          [
            _c("div", { staticClass: "vs-table-text pl-6" }, [
              _vm._v("Paquetes tradicionales"),
            ]),
          ]
        ),
        _c(
          "th",
          {
            staticStyle: { "border-radius": "0% 8px 8px 0%" },
            attrs: { colspan: "1", rowspan: "1" },
          },
          [
            _c("div", { staticClass: "vs-table-text" }, [
              _vm._v(
                "\n                        Tus Servicios tradicionales de forma clara y\n                        sencilla\n                    "
              ),
            ]),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }