<template>
  <div class="bg-white py-2 sm:py-2">
    <div class="mx-auto max-w-7xl px-6 lg:px-8" >
      <div class="mx-auto lg:mx-0 title-bar">
        <p class="mt-2 text-lg leading-8 text-gray-600 poli-text">Seleccionaste poligrafía</p>
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl titlep">¿Qué clase de poligrafía requieres?</h2>
      </div>
      <div class="mx-auto mt-10 grid max-w-l grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-6 sm:pt-6 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <article v-for="poligraph in poligraphTypes" :key="poligraph.nick" class="flex max-w-xl flex-col items-start justify-between poli-cards">
          <div class="group relative">
            <div style="display: flex;">
              <vs-radio v-model="poligraphyType" vs-name="poligraplyType" :vs-value="poligraph.nick"></vs-radio>
              <h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600" style="padding-bottom: 7px;">
                <a>
                  {{ poligraph.title }}
                </a>
              </h3>
            </div>
            <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{{ poligraph.description }}</p>
          </div>
          <div class="relative mt-8 flex items-center gap-x-4">
            <img :src="poligraph.author.imageUrl" alt="" class="h-3 w-3 rounded-full bg-gray-50" />
            <div class="flex items-center gap-x-4 text-xs">
              <p class="text-gray-500"><strong>Tiempo aproximado: {{ poligraph.timeAprox }}</strong></p>
            </div>
          </div>
        </article>
      </div>
      <div v-if="poligraphyType == 'pre-emple'" class="relative mt-8 flex items-center gap-x-4 title-bar">
        <img style="margin-left: 10px;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Danger-Circle.png" alt="" class="h-6 w-6 rounded-full bg-gray-50" />
        <p class="mt-2 text-md leading-8 text-gray-600 poli-text">Recuerda que dependemos de la respuesta oportuna del candidato para comenzar con el servicio.
          Al crear este proceso buscaremos la asignación de nuestros profesionales de manera inmediata. Al momento de ser asignado se le enviaran las recomendaciones
          a la persona que realizará la prueba.</p>
      </div>
      <div v-if="this.poligraphyType == null && this.formFailed" class="mx-auto grid max-w-l grid-cols-1 gap-x-8 border-t border-gray-200 pt-5 sm:mt-3 sm:pt-3 lg:mx-0">
        <span class="text-danger">Selecciona al menos una poligrafía para continuar</span>
      </div>
      <div v-if="poligraphyType == 'ruti'" class="mx-auto mt-10 grid max-w-l grid-cols-1 gap-x-8 border-t border-gray-200 pt-5 sm:mt-3 sm:pt-3 lg:mx-0">
        <p style="margin-bottom: 10px;">Cuéntanos brevemente cual es el motivo de la prueba (Después te solicitaremos más información especifica al respecto).</p>
          <div>
            <vs-textarea v-model="poligraphReason" />
         </div>
         <div class="mb-2" v-if="(this.poligraphReason == null || this.poligraphReason == '') && this.formFailed && this.poligraphyType != 'pre-emple'">
            <p class="text-danger">Escribe algo para continuar</p>
         </div>
         <p style="margin-bottom: 10px;">Sugiere una posible fecha de disponibilidad de los colaboradores</p>
         <div style="display: flex;">
          <img style="z-index: 1;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Group.png" alt="" class="h-6 w-6 bg-gray-50 calendar-icon" />
            <vue-flatpickr style="position: absolute; z-index: 0;"
              id="poliDate"
              v-model="poligraphDate"
              :config="flatpickrOptions"
            ></vue-flatpickr>
            <img :style="{opacity: opacityState, cursor: poligraphDateSecond ? 'not-allowed' : 'pointer' }" @click="toggleContent" style="margin-left: 210px; margin-top: 5px;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/M.png" alt="">
         </div>
         <div v-show="isContentVisible" class="expandable-content mt-5" >
          <div class="mt-3 mb-5" style="display: flex;">
            <img style="z-index: 1;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Group.png" alt="" class="h-6 w-6 bg-gray-50 calendar-icon" />
            <vue-flatpickr style="position: absolute;"
              id="poliDateSecond"
              v-model="poligraphDateSecond"
              :config="flatpickrOptions"
            ></vue-flatpickr>
            <img @click="deletePoligraphDateSecond" style="margin-left: 210px; margin-top: 5px;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Delete.png" alt="">
          </div>
         </div>
        <div class="relative mt-8 flex items-center gap-x-4 title-bar">
          <img style="margin-left: 10px;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Danger-Circle.png" alt="" class="h-6 w-6 rounded-full bg-gray-50" />
          <p class="mt-2 text-md leading-8 text-gray-600 poli-text"><strong>Esto no garantiza que los cupos se reserven para esta fecha, pero buscaremos las fechas más cercanas.</strong> Al momento de
             ser asignado le enviaremos las recomendaciones a la persona de tu empresa encargada del proceso.</p>
        </div>
      </div>
      <div v-if="poligraphyType == 'especi'" class="mx-auto mt-10 grid max-w-l grid-cols-1 gap-x-8 border-t border-gray-200 pt-5 sm:mt-3 sm:pt-3 lg:mx-0">
        <p style="margin-bottom: 10px;">Cuéntanos brevemente cual es el motivo de la prueba (Después te solicitaremos más información especifica al respecto).</p>
          <div>
            <vs-textarea v-model="poligraphReason" />
         </div>
         <div class="mb-2" v-if="(this.poligraphReason == null || this.poligraphReason == '') && this.formFailed && this.poligraphyType != 'pre-emple'">
            <p class="text-danger">Escribe algo para continuar</p>
         </div>
         <p style="margin-bottom: 10px;">Sugiere una posible fecha de disponibilidad de los colaboradores</p>
         <div style="display: flex;">
          <img style="z-index: 1;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Group.png" alt="" class="h-6 w-6 bg-gray-50 calendar-icon" />
            <vue-flatpickr style="position: absolute; z-index: 0;"
              id="poliDate"
              v-model="poligraphDate"
              :config="flatpickrOptions"
            ></vue-flatpickr>
            <img :style="{opacity: opacityState, cursor: poligraphDateSecond ? 'not-allowed' : 'pointer' }" @click="toggleContent" style="margin-left: 210px; margin-top: 5px;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/M.png" alt="">
         </div>
         <div v-show="isContentVisible" class="expandable-content mt-5">
          <div class="mt-3 mb-5" style="display: flex;">
            <img style="z-index: 1;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Group.png" alt="" class="h-6 w-6 bg-gray-50 calendar-icon" />
            <vue-flatpickr style="position: absolute;"
              id="poliDateSecond"
              v-model="poligraphDateSecond"
              :config="flatpickrOptions"
            ></vue-flatpickr>
            <img @click="deletePoligraphDateSecond" style="margin-left: 210px; margin-top: 5px;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Delete.png" alt="">
          </div>
         </div>
        <div class="relative mt-8 flex items-center gap-x-4 title-bar">
          <img style="margin-left: 10px;" src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Danger-Circle.png" alt="" class="h-6 w-6 rounded-full bg-gray-50" />
          <p class="mt-2 text-md leading-8 text-gray-600 poli-text"><strong>Esto no garantiza que los cupos se reserven para esta fecha, pero buscaremos las fechas más cercanas.</strong> Al momento de
             ser asignado le enviaremos las recomendaciones a la persona de tu empresa encargada del proceso.</p>
        </div>
      </div>
      <div v-if="this.poligraphyType != 'especi' && this.poligraphyType" class="pt-6 text-lg leading-8 text-gray-600">
        <p style="padding-top: 25px;">Selecciona el formato de la poligrafía</p>
        <div class="relative mt-6 flex items-center gap-x-4">
          <div class="relative mt-2 flex items-center gap-x-4 mr-4" style="color: #634871;">
            <vs-radio v-model="poligraphVirtual" vs-value=false ></vs-radio>
            <p>Presencial (Prueba de poligrafo)</p>
          </div>
          <div class="relative mt-2 flex items-center gap-x-4" style="color: #634871;">
            <vs-radio v-model="poligraphVirtual" vs-value=true ></vs-radio>
            <p>Virtual (VSA - Voice Analysis System)</p>
          </div>
        </div>
        <div v-if="this.poligraphVirtual == null && this.formFailed && this.poligraphyType != 'especi'" class="mx-auto grid max-w-l grid-cols-1 gap-x-8 border-t border-gray-200 pt-5 sm:mt-3 sm:pt-3 lg:mx-0">
          <span class="text-danger">Selecciona al menos una poligrafía para continuar</span>
        </div>
    </div>
    <div v-if="this.poligraphyType == 'especi'" class="relative mt-4 flex items-center gap-x-4 pl-5 pt-3">
          <img src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Danger-Circle.png" alt="" class="h-6 w-6 rounded-full bg-gray-50" />
          <p class="mt-2 text-md leading-8 text-gray-600 poli-text">El servicio de poligrafía específica solo se presta de manera presencial (Prueba de poligrafo)</p>
        </div>
    </div>
  </div>
</template>


<script>

export default {

  props: {
    formFailed: Boolean
  },

  data() {
    return{
      opacityState: 0.3,
      isContentVisible: false,
      isFormValid: false,
      poligraphDate: null,
      poligraphDateSecond: null,
      flatpickrOptions: {
        enableTime: true,
        dateFormat: 'Y-m-d h:i K',
        time_24hr: false,
        minDate: "today"
      },
      poligraphReason: null,
      poligraphVirtual: null,
      poligraphyType: null,
      poligraphTypes: [
  {
    id: 1,
    title: 'Poligrafía pre-empleo',
    nick: 'pre-emple',
    href: '#',
    description:
      'Es la prueba más utilizada. Se verifica la precisión de la información proporcionada y se evalúan los riesgos de la contratación.',
    timeAprox: '1 a 3 días.',
    author: {
      imageUrl:
        'https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Time-Circle.png',
    },
  },
  {
    id: 2,
    title: 'Poligrafía de rutina',
    nick: 'ruti',
    href: '#',
    description:
      'Procedimiento indicado para actualizar la información del colaborador(a) y detectar posibles riesgos que vulneren la seguridad de la empresa.',
    timeAprox: '1 a 3 días.',
    author: {
      imageUrl:
        'https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Time-Circle.png',
    },
  },
  {
    id: 3,
    title: 'Poligrafía específica',
    nick: 'especi',
    href: '#',
    description:
      'Nos enfocamos en incidentes particulares y se utiliza cuando hay sospechas de un comportamiento inapropiado o ilegal por parte de un individuo.',
    timeAprox: '1 a 3 días.',
    author: {
      imageUrl:
        'https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Time-Circle.png',
    },
  },
]
    }
  },
  methods: {
    toggleContent() {
      if (this.poligraphDateSecond) {
      return;
      }
      if (this.isContentVisible == false && this.poligraphDate) {
        this.poligraphDateSecond = null
        this.opacityState = 0.3;
      }else if (this.poligraphDate){
        this.opacityState = 1;
      }
      if (this.poligraphDate || this.isContentVisible ) {
        this.isContentVisible = !this.isContentVisible;
      }

    },
    deletePoligraphDateSecond() {
      this.poligraphDateSecond = null;
      this.isContentVisible = false;
      this.opacityState = 1;
    },
    validateForm() {
      if (this.poligraphyType == 'ruti') {
      this.isFormValid = Boolean(
      this.poligraphyType &&
      this.poligraphReason &&
      this.poligraphVirtual !== null
    );
      }else if(this.poligraphyType == 'pre-emple') {
      this.isFormValid = Boolean(
      this.poligraphyType &&
      this.poligraphVirtual !== null
    );
      }else if(this.poligraphyType == 'especi') {
      this.isFormValid = Boolean(
      this.poligraphyType &&
      this.poligraphReason
    );
      }
    this.$emit('update-form-validity', this.isFormValid);
  },
  iconOpacity(newDate){
    if (newDate) {
      this.opacityState = 1
    } else {
      this.opacityState = 0.3
    }
  }
  },
  watch: {
    poligraphDateSecond(newVal) {
    if (newVal) {
      this.opacityState = 0.3;
    } else {
      this.opacityState = 1;
    }
    this.$emit('update-poligraph-date-second', newVal);
  },
    poligraphDate: function (newDate) {
    this.$emit('update-poligraph-date', newDate);
    this.validateForm();
    this.iconOpacity(newDate);
  },
  poligraphReason: function (newReason) {
    this.$emit('update-poligraph-reason', newReason);
    this.validateForm();
  },
  poligraphyType: function (newType) {
    this.$emit('update-poligraphy-type', newType);
    this.validateForm();
  },
  poligraphVirtual: function (newVirtual) {
    this.$emit('update-poligraph-virtual', newVirtual);
    this.validateForm();
  },
  },

}
</script>

<style>
.expandable-content {
  transition: max-height 0.3s ease-out;
  max-height: 200px;
  overflow: hidden;
}
.poli-cards{
  padding: 15px;
  border: solid;
  border-color: #F2EDFC;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 8px 0px rgba(95, 0, 144, 0.25);
}

.title-bar{
  border-radius: 8px;
  background: #F2EDFC;
  padding: 10px;
}

.title-bar .titlep{
  color: var(--Morado-Globalwork, #5F008F);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.title-bar .poli-text{
  color: #3C1A4E;
  margin-bottom: 10px;
}

.calendar-icon{
  position: relative;
  transform: translate(170px, 6px);
}

</style>
