<template>

  <div class="container mx-aut">
    <div class="vx-card__collapsible-content vs-con-loading__container">
      <form-wizard color="rgba(var(--vs-primary), 1)" errorColor="rgba(var(--vs-danger), 1)" finishButtonText="Finalizar" backButtonText="Anterior" nextButtonText="Siguiente"
          @on-complete="createProject" ref="wizard" @on-change="scrollUp" style="height: 970px;">

          <h2 class="custom-title" slot="title">Crear proceso</h2>
          <h3 class="custom-title subtitle" slot="title">Por favor llena todos los campos</h3>

        <!-- Paso 1 -->
        <tab-content title="Información del candidato" class="mb-5" icon="feather icon-user" :before-change="validateStep1" >
          <form autocomplete="off" data-vv-scope="step-1">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full mt-5">
                <label class="form-labels" for="">Nombres</label>
                <vs-input id="firstNameCandidate" v-model="project.first_name" @keypress="regExpressionText" class="w-full" name="project.first_name" maxlength="50" v-validate="'max:50|required'" />
                <span class="text-danger" v-show="errors.has('project.first_name')">Requerido</span>
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                <label class="form-labels" for="">Apellidos</label>
                <vs-input id="lastNameCandidate" v-model="project.last_name" @keypress="regExpressionText" class="w-full" name="project.last_name" maxlength="50" v-validate="'max:50|required'" />
                <span class="text-danger" v-show="errors.has('project.last_name')">Requerido</span>
              </div>

              <div class="vx-col md:w-1/2 w-full mt-6">
                <label class="form-labels" for="">Email</label>
                <vs-input id="emailCandidate" v-model="project.email_1" class="w-full" name="project.email_1" v-validate="'required|email'" />
                <span class="text-danger" v-show="errors.has('project.email_1')">Requerido</span>
              </div>
              <div class="vx-col md:w-1/2 w-full mt-6">
                <label class="form-labels" for="">Celular</label>
                <vs-input id="phoneCandidate" v-model="project.telephone_1" class="w-full" name="project.telephone_1" v-validate="'min:10|required|numeric'"  maxlength="10"/>
                <span class="text-danger" v-show="errors.has('project.telephone_1')">Requerido (solo caracteres númericos de 10 dígitos)</span>
              </div>
              <div style="-webkit-margin-before:1.4%;" class="vx-col md:w-1/2 w-full mt-8">
                <label class="vs-select--label form-labels" for="">Tipo de documento</label>
                <v-select style="-webkit-margin-before:0% !important;" :options="idTypes" :reduce="city => city.id" v-model="project.person_id_type" placeholder="Seleccione una opción" class="w-full select-large mt-5" label="label"
                    id="project.person_id_type" name="project.person_id_type" v-validate="'required'">
                </v-select>
                <span class="text-danger text-sm" v-show="errors.has('project.person_id_type')">Requerido</span>
              </div>
              <!--El input cambia -->
              <div class="vx-col md:w-1/2 w-full mt-8">
                <label class="form-labels" for="">Número de documento</label>
                <vs-input v-if="project.person_id_type > 2" @keypress="onlyText" @paste="onPaste" id="project.person_id" v-model="project.person_id" class="w-full" name="project.person_id"
                    v-validate="'min:5|required|alpha_num'" />
                <vs-input v-else @keypress="onlyText" @paste="onPaste" id="project.person_id" v-model="project.person_id" class="w-full" name="project.person_id"
                    v-validate="'min:5|required|numeric'"/>
                <span class="text-danger" v-show="errors.has('project.person_id')">Requerido</span>
              </div>
            </div>
            <div class="vx-row mt-3 pb-2">
              <div style="-webkit-margin-before:1.6%;" class="vx-col md:w-1/3 w-full">
                <label class="vs-select--label form-labels" for="">Nivel educativo</label>
                <v-select style="-webkit-margin-before:0% !important;" id="selectCountry" :options="educationLevels" :reduce="level => level.id" v-model="project.education_level" placeholder="Nivel educativo" class="w-full select-large mt-5" label="label"
                  name="project.education_level" v-validate="'required'">
                </v-select>
                <span class="text-danger text-sm" v-show="errors.has('project.education_level')">Requerido</span>
              </div>
              <div style="-webkit-margin-before:1.6%;" class="vx-col md:w-1/3 w-full">
                <label class="vs-select--label form-labels" for="">País</label>
                <v-select style="-webkit-margin-before:0% !important;" id="selectCountry" :options="countries" :reduce="country => country.id" v-model="country_id" placeholder="Seleccionar país" class="w-full select-large mt-5" label="name"
                    name="country_id" v-validate="'required'">
                </v-select>
              </div>
              <div style="-webkit-margin-before:1.6%;" class="vx-col md:w-1/3 w-full">
                <label class="vs-select--label form-labels" for="">Ciudad</label>
                <v-select style="-webkit-margin-before:0% !important;" id="selectCity" :options="cities" :reduce="city => city.id" v-model="project.city_id" placeholder="Seleccionar ciudad" class="w-full select-large mt-5" label="name"
                    name="project.city_id" v-validate="'required'">
                </v-select>
                <span class="text-danger text-sm" v-show="errors.has('project.city_id')">Requerido</span>
              </div>
            </div>
          </form>
        </tab-content>

        <!-- Paso 2 -->
        <tab-content title="Información del proceso" class="mb-5" icon="feather icon-clipboard" :before-change="validateStep2">
          <form data-vv-scope="step-2">
            <div class="vx-row">
              <products :contract_id.sync="contract_id" :productsProject.sync="productsProject" />
            </div>

            <div v-if="productsProject!= null && [13,6,12,15,18].includes(productsProject[0])" class="vx-row">
              <div class="vx-col md:w-1/1 w-full md:mt-8">
                <div class="demo-alignment">
                  <div class="flex" style="flex-direction:column;">
                    <span style="font-size:18px;">Selecciona el formato de la visita</span>
                    <vs-radio v-model="projectIsVirtual" :vs-value="false" vs-name="is-virtual" name="project.projectIsVirtual" v-validate="'required'">
                      Hacer la visita presencial
                    </vs-radio>
                    <vs-radio v-model="projectIsVirtual" :vs-value="true" vs-name="is-virtual" name="project.projectIsVirtual" v-validate="'required'">
                      Hacer la visita virtual
                    </vs-radio>
                    <span class="text-danger" v-show="errors.has('project.projectIsVirtual')">Requerido</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="vx-row">
              <hr />
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mt-5">
                <vs-input id="chargeToApply" label="Cargo aspirante / colaborador" @keypress="regExpressionTextNumbers" v-model="project.current_activity" class="w-full" name="project.current_activity" v-validate="'required'" />
                <span class="text-danger text-sm" v-show="errors.has('project.current_activity')">Requerido</span>
              </div>
            </div>

            <div class="vx-row" v-if="businessUnits && businessUnits.length > 0">
              <div class="vx-col md:w-1/1 w-full mt-5">
                  <vs-select id="selectBusinessUnit" v-model="businessUnit" label="Empresa contratante" name="businessUnit" class="w-full select-large mt-5">
                    <vs-select-item v-for="unit, index in businessUnits" :key="index" :value="unit.id" :text="unit.name" class="w-full"/>
                  </vs-select>
                <span class="text-danger text-sm" v-show="errors.has('businessUnit')">Requerido</span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mt-5">
                <vs-input id="billingCenter" label="Centro de costo" @keypress="regExpressionTextNumbers" v-model="project.billing_center" class="w-full" name="project.billing_center" />
                <span class="text-danger text-sm" v-show="errors.has('project.billing_center')">Requerido</span>
              </div>
            </div>
          </form>
        </tab-content>

        <!-- Paso 3 -->
        <tab-content title="Información Adicional" class="mb-5" icon="feather icon-briefcase" :before-change="validateStep3" >
          <form data-vv-scope="step-3">
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full md:mt-8">
                <div v-if="productsProject == 4" class="vx-row">
                  <PoligraphyCards :formFailed="formFailed" @on-form-failure="handleFormFailure" @update-poligraph-date-second="handlePoligraphDateSecondUpdate" @update-poligraph-date="handlePoligraphDateUpdate" @update-poligraph-reason="handlePoligraphReasonUpdate" @update-poligraphy-type="handlePoligraphyTypeUpdate" @update-poligraph-virtual="handlePoligraphVirtualUpdate" @update-form-validity="handleFormValidityUpdate"/>
                </div>
                <vs-divider style="padding-top: 16px;"/>
                <div class="demo-alignment ml-2">
                  <div class="flex">
                    <vs-checkbox id="allowanceAuthorization" v-model="project.allowance_authorization">Autorización de viáticos</vs-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row mb-6" style="margin-left: 0.5px;">
              <div class="vx-col md:w-1/1 w-full mt-5">
                <vs-input id="travelAllownce" label="Cantidad de viáticos" v-model="project.travel_allowance" class="w-full" name="travel_allowance" type="text" @keypress="onlyNumbers" @paste="onlyNumbersOnPaste"/>
              </div>
            </div>

            <!-- Ocultar sólo con procesos de poligrafía -->
            <div v-if="productsProject !== null && productsProject[0] !== 3 && hideDatacredit === false" class="vx-row ml-2 mb-6">
              <div class="vx-col md:w-1/1 w-full md:mt-8 pb-3 pt-0 title-bar">
                <div class="demo-alignment" style="justify-content: space-between;">
                  <div class="flex ml">
                    <vs-checkbox v-model="project.credit_validation"></vs-checkbox>
                    <label>¿Quieres incluir la verificación en centrales de riesgo (Datacrédito)?</label>
                  </div>
                  <div>
                      <img src="https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Wallet.png" alt="">
                    </div>
                </div>
              </div>
            </div>

            <div class="vx-row pl-1">
              <div style="margin-top:21px;" class="vx-col md:w-1/1 w-full">
                <label for="" style="font-size:15px;">¿Tienes alguna instrucción u observación especial sobre el proceso?</label>
                <vs-input @keypress="regExpressionTextNumbers" v-model="project.observations" class="w-full mt-5" name="project.observations" maxlength="2500" v-validate="'max:2500'"/>
                <span class="text-danger" v-show="errors.has('project.observations.length > 2500')">Requerido</span>
              </div>

              <!-- <div class="vx-col md:w-1/2 w-full mt-5" v-if="productsProject!= null && productsProject[0] == 18">
                <vs-input label="Telefono" id="telephone_candidate" v-model="project.telephone_1" class="w-full" name="project.telephone_1" v-validate="'required'" />
                <span class="text-danger" v-show="errors.has('project.telephone_1')">Requerido</span>
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5" v-if="productsProject!= null && productsProject[0] == 18">
                <vs-input label="Email" id="emailCandidate" v-model="project.email_1" class="w-full" name="project.email_1" v-validate="'required'" />
                <span class="text-danger" v-show="errors.has('project.email_1')">Requerido</span>
              </div> -->
            </div>
            <!-- <div class="vx-row">
                <div class="vx-col md:w-1/1 w-full md:mt-8">
                  <div class="flex">
                    <vs-checkbox id="allowanceAuthorization" v-model="contact_candidate"></vs-checkbox>
                    ¿Nos autorizas a solicitarle al candidato información sobre sus referencias académicas, laborales y personales? Esto optimiza el proceso, pues el candidato es el responsable de compartirnos los datos necesarios.
                  </div>
                </div>
              </div>
              <div class="vx-row" v-if="contact_candidate">
                <label for="" style="font-size:15px; margin-left: 1.5rem; margin-top: 2rem">Por favor ingresa el email y el celular del candidato</label>
              </div>
              <div class="vx-row" v-if="contact_candidate">
                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Email" id="emailCandidate" v-model="project.email_1" class="w-full" name="project.email_1" v-validate="'required|email'" />
                  <span class="text-danger" v-show="errors.has('project.email_1')">Requerido</span>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Celular" id="phoneCandidate" v-model="project.telephone_1" class="w-full" name="project.telephone_1" v-validate="'required|numeric'" maxlength="10"/>
                  <span class="text-danger" v-show="errors.has('project.telephone_1')">Requerido (solo caracteres númericos)</span>
                </div>
              </div> -->
          </form>
        </tab-content>

        <!-- Paso 4 -->
        <tab-content title="Documentos del candidato" class="mb-5" icon="feather icon-file" :before-change="validateStep3">
          <form data-vv-scope="step-4">
            <div class="files-candiate">
              <div class="list-documents">
                <vs-list>
                  <vs-list-header icon="format_list_numbered" title="Documentos necesarios para iniciar el proceso"></vs-list-header>
                  <ul>

                    <div  v-for="(type, typeFileIndex) in typeFiles" :key="type.id">
                      <div class="accordion-header" @click="toggleAccordion(typeFileIndex, false)">
                        <div class="item-document">
                          <div class="column">
                            <vs-button class="color-purple icon-add text-size" type="flat" :disabled="allowsNewFile(type.type_file)" @click.prevent="assignFile(type.type_file), toggleAccordion(typeFileIndex, true)" @click.stop>
                              <span class="material-icons item-icon">drive_folder_upload</span> {{ type.label }}
                            </vs-button>
                          </div>
                        <div class="column-2">
                          <span class="max-upload">Máximo {{ type.maxUploads }} {{ type.maxUploads == 1 ? 'archivo' : 'archivos'}}</span>
                        </div>
                          <vs-button class="icon-add" type="transparent" @click.prevent>
                            <span :id="`chevron${typeFileIndex}`" class="material-icons">expand_more</span>
                          </vs-button>
                        </div>

                        <div class="accordion-content" :id="`content${typeFileIndex}`">
                          <div  v-for="file in files" :key="file.input_id">
                              <span v-if="type.type_file === file.id">
                                <div v-if="file.content !== null" class="item-document">
                                    <div>
                                      <span class="material-icons item-icon color-green">check</span>
                                      <span v-if="file.name_upload.length < 30" class="text-size color-green" @click.stop> {{ file.name_upload }}</span>
                                      <span v-else class="text-size color-green" @click.stop> {{ file.name_upload.substring(0, 40) + '...' }}</span>
                                    </div>
                                    <vs-button class="item-icon" color="danger" type="flat" @click.prevent="clearInputsFile(file)" @click.stop><span class="material-icons item-icon">clear</span></vs-button>
                                </div>
                              </span>
                          </div>
                          <div v-if="noFilesAdded(typeFileIndex)" class="item-document">
                            <span class="no-added-files"> <span class="material-icons item-icon">info</span> No se han agregado archivos</span>
                          </div>
                        </div>
                      </div>
                    </div>

                  </ul>
                </vs-list>
                <div v-for="file in files" :key="file.input_id">
                  <input type="file" style="display: none;" :id="`inputFile${file.input_id}`" :ref="`inputFile${file.input_id}`" @change="preChargeFiles(file.input_id)" accept="image/png, image/jpg, image/jpeg, application/pdf"/>
                </div>
                <div v-show="this.non_valid_message" class="extension-error">
                  <p  class="extension-message">Solo se permite subir formatos en png, jpg, jpeg y pdf.</p>
                </div>
                <div v-show="this.non_valid_size" class="extension-error">
                  <p class="extension-message">El tamaño del archivo debe ser menor a 2 MB.</p>
                </div>
              </div>
            </div>
          </form>
        </tab-content>

        <div class="loader-create" v-if="showLoader">
          <Loader show="true" />
        </div>
      </form-wizard>
    </div>
  </div>

</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import Cities from '../../../mixins/cities'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import api from '../../../mixins/api'
import Products from './../../../components-globalwork/projects/Products.vue'
import { mapGetters, mapMutations } from 'vuex'
import validateFile from './../../../mixins/validateFile'
import Select from './../../../components/own-component/select-local'
import Loader from './../../../views/Loader.vue'
import PoligraphyCards from './../../../components-globalwork/projects/PoligraphyCards.vue';
import Axios from 'axios';
export default {
  mixins: [api, validateFile],
  name: 'CreateProjects',
  props: {
    popupActive: Boolean
  },
  data() {
    return {
      formFailed: false,
      isFormValid: false,
      businessUnits: [],
      businessUnit: '',
      projectIsVirtual: null,

      showLoader: false,
      type_file: null,
      perPage: 10,
      page: 1,
      typeFiles:[
        {
          type_file: 0, name:'Hoja de vida', label: 'Subir Hoja de Vida *', maxUploads: 1
        },
        {
          type_file: 1, name: 'Habeas Data', label: 'Subir Habeas Data *', maxUploads: 1
        },
        {
          type_file: 2, name:'Diploma Academico', label: 'Subir Diploma Académico', maxUploads: 4
        },
        {
          type_file: 3, name: 'Certificado Academico', label: 'Subir Certificado Académico', maxUploads: 4
        },
        {
          type_file: 4, name: 'Certificado Laboral', label: 'Subir Certificado Laboral', maxUploads: 4
        }
      ],
      contract_id: null,
      productsProject: null,
      project: {},
      poligraph: {},
      countries: [],
      cities: [],
      country_id: null,
      idTypes: [
        { id: null, label: 'Seleccione una opción' },
        { id: 1, label: 'Cédula' },
        { id: 2, label: 'Tarjeta de identidad' },
        { id: 3, label: 'Pasaporte' },
        { id: 4, label: 'Cédula de extranjería' },
      ],
      educationLevels: [
        { id: null, label: 'Seleccione una opción' },
        { id: 0, label: 'Primaria' },
        { id: 1, label: 'Bachiller' },
        { id: 2, label: 'Profesional Universitario' },
        { id: 3, label: 'Técnico Profesional' },
        { id: 4, label: 'Otro' },
        { id: 5, label: 'Tecnólogo' },
        { id: 6, label: 'Especialista' },
        { id: 7, label: 'Maestria' },
        { id: 8, label: 'Doctorado' }

      ],
      projectTypes: [
        { id: null, label: 'Seleccione una opción' },
        { id: 0, label: 'Visita domiciliaria' },
        { id: 1, label: 'Prueba psicotécnica' },
        { id: 2, label: 'Estudio de seguridad' },
        { id: 3, label: 'Listados de riesgo' },
        { id: 4, label: 'Prueba de poligrafía' },
        { id: 5, label: 'Antecedentes Judiciales' },
        { id: 6, label: 'Visita Domiciliaria Basica' },
        { id: 7, label: 'Academica y Antecedentes' },
        { id: 8, label: 'Verificacion Academica' },
        { id: 9, label: 'Referencias, Academicas y Laborales' },
        { id: 12, label: 'Visita OEA' },
        { id: 15, label: 'Visita teletrabajo completo' }
      ],
      project_id_create: 0,
      dropzoneOptions: {
        url: '',
        thumbnailWidth: 150,
        maxFilesize: 20,
        headers: {},
        dictDefaultMessage: 'Arrastrar y soltar los archivos'
      },
      project_create: false,
      percentajeForm: 0,
      popupActive3: false,
      contact_candidate: true,
      city: '',
      non_valid_message: false,
      non_valid_size: false,
      files: [],
      filesTemplate: [
        {
          id: 0, input_id: 0, name_archive: 'Hoja de vida', name:'Subir Hoja de vida *', name_upload: 'Hoja de vida cargada', content: null
        },
        {
          id: 1, input_id: 1, name_archive: 'Habeas data', name:'Subir Habeas Data *', name_upload: 'Habeas data cargado', content: null
        },
        {
          id: 2, input_id: 2, name_archive: 'Diploma académico', name:'Subir Diploma académico', name_upload: 'Diploma académico cargada', content: null
        },
        {
          id: 2, input_id: 3, name_archive: 'Diploma académico', name:'Subir Diploma académico', name_upload: 'Diploma académico cargada', content: null
        },
        {
          id: 2, input_id: 4, name_archive: 'Diploma académico', name:'Subir Diploma académico', name_upload: 'Diploma académico cargada', content: null
        },
        {
          id: 2, input_id: 5, name_archive: 'Diploma académico', name:'Subir Diploma académico', name_upload: 'Diploma académico cargada', content: null
        },
        {
          id: 3, input_id: 6, name_archive: 'Certificado académico', name:'Subir Certificado académico', name_upload: 'Certificado académico cargado', content: null
        },
        {
          id: 3, input_id: 7, name_archive: 'Certificado académico', name:'Subir Certificado académico', name_upload: 'Certificado académico cargado', content: null
        },
        {
          id: 3, input_id: 8, name_archive: 'Certificado académico', name:'Subir Certificado académico', name_upload: 'Certificado académico cargado', content: null
        },
        {
          id: 3, input_id: 9, name_archive: 'Certificado académico', name:'Subir Certificado académico', name_upload: 'Certificado académico cargado', content: null
        },
        {
          id: 4, input_id: 10, name_archive: 'Certificado laboral', name:'Subir Certificado laboral', name_upload: 'Certificado laboral cargado', content: null
        },
        {
          id: 4, input_id: 11, name_archive: 'Certificado laboral', name:'Subir Certificado laboral', name_upload: 'Certificado laboral cargado', content: null
        },
        {
          id: 4, input_id: 12, name_archive: 'Certificado laboral', name:'Subir Certificado laboral', name_upload: 'Certificado laboral cargado', content: null
        },
        {
          id: 4, input_id: 13, name_archive: 'Certificado laboral', name:'Subir Certificado laboral', name_upload: 'Certificado laboral cargado', content: null
        }
      ]
    }
  },
  methods: {
    handleFormFailure() {
      this.formFailed = true;  // Actualiza el estado en el componente padre
    },
    handlePoligraphDateSecondUpdate(updatedDateSecond) {
      this.poligraph.poligraph_sugested_date_second = updatedDateSecond
    },
    handleFormValidityUpdate(isFormValid) {
      this.isFormValid = isFormValid;
    },
    handlePoligraphDateUpdate(updatedDate) {
      this.poligraph.poligraph_sugested_date = updatedDate
    },
    handlePoligraphReasonUpdate(updatedReason) {
      this.poligraph.poligraph_reason = updatedReason
    },
    handlePoligraphyTypeUpdate(updatedType) {
      this.poligraph.poligraph_type = updatedType
    },
    handlePoligraphVirtualUpdate(updatedVirtual) {
      this.poligraph.poligraph_virtual = updatedVirtual
    },
    ...mapMutations(['changeColumnEvent'],["UPDATE_FAST_TRACKING_PROJECTS", "UPDATE_FAST_TRACKING_PROJECTS_COUNT"]),
    iconItemDocument(file) {
      if (file.content == null) {
        return 'drive_folder_upload'
      } else {
        return 'check'
      }
    },
    onlyNumbers(event) {
      if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    },
    onlyNumbersOnPaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData('text');
      if (!/^\d+$/.test(pastedText)) {
        event.preventDefault();
      }
    },
    nameItem(file) {
      if (file.content == null) {
        return file.name
      } else {
        return file.name_upload
      }
    },
    colorButtonUpload(file) {
      if (file.content == null) {
        return 'primary'
      } else {
        return 'success'
      }
    },
    onPickFile(typeFileId) {
      const ref = `inputFile${typeFileId}`
      this.$refs[ref][0].click()
    },
    async preChargeFiles(typeFileId) {
      const ref = `inputFile${typeFileId}`
      const file = this.$refs[ref][0].files[0]
      const valid_file = this.checkFile(file.name)
      if (file.size < 2000000) {
        if (valid_file) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const result = await new Promise((resolve) => {
            reader.onload = function() {
              resolve(reader.result)
            };
          })
          this.setContentFile(typeFileId, result, file.name)
        } else {
          this.clearInputsFile(this.files[typeFileId])
        }
      } else {
          this.non_valid_size = true
          setTimeout(() => this.non_valid_size = false, 5000)
          return false
      }
    },
    setContentFile(typeFileId, content, name) {
      this.files.forEach(element => {
        if (element.input_id == typeFileId) {
          element.content = content
          element.name_upload = ''
          element.name_upload = `${this.filesTemplate.filter((fileTemplate) => fileTemplate.input_id == typeFileId)[0].name_archive} (${name})`
        }
      });
    },
    clearInputsFiles() {
      this.files.map((file) => this.clearInputsFile(file));
    },
    clearInputsFile(file) {
      this.files.forEach(element => {
        if (element.input_id == file.input_id) {
          element.content = null
        }
      });
      let ref = `#inputFile${file.input_id}`
      document.querySelector(ref).value = null
    },
    assignFile(type_file){
      let index = this.files.findIndex((file) => file.id === type_file && file.content == null)
      let file = this.files[index]
      this.resetChevrons()
      this.resetAccordions()
      this.onPickFile(file.input_id)
    },
    allowsNewFile(type_file){
      const typeFile = this.typeFiles.findIndex(tp => tp.type_file === type_file)
      const maxUploads = this.typeFiles[typeFile].maxUploads
      let filesUploaded = this.files.filter((file) => file.id === type_file && file.content !== null);
      return filesUploaded.length >= maxUploads
    },
    checkFile(content) {
        const fileElement = content
        let fileExtension = "";
        if (fileElement.lastIndexOf(".") > 0) {
            fileExtension = fileElement.substring(fileElement.lastIndexOf(".") + 1, fileElement.length);
        }
        if (['pdf', 'png', 'jpg', 'jpeg'].includes(fileExtension.toLowerCase())) {
            return true
        }
        else {
            this.non_valid_message = true
            setTimeout(() => this.non_valid_message = false, 5000)
            return false
        }
    },
    goToInstruction() {
      let routeData = this.$router.resolve({ name: 'instruction' });
      window.open(routeData.href, '_blank');
    },
    async getBusinessUnits() {
        try {
            let { client_id } = this.$store.getters.userInfo.user
            let { data } = await this.$http.get(`${ this.url() }api/v1/business_units`, {
                params: {
                    client_id
                }
            })

            this.businessUnits = data
            this.businessUnits.push({
                id: '',
                name: 'Selecciona una opción'
            })
        } catch (error) {
            console.error(error)
        }
    },
    scrollUp() {
      window.scrollTo(0, 0);
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('step-1').then(result => {
          if (result) {
              resolve(true)
          } else {
            reject("correct all values");
            this.scrollUp();
          }
        })
      })
    },
    validateStep2() {
      let str = /[^a-zA-Z0-9]/g;
      this.project.person_id = this.project.person_id.replace(str, '')
      const date = new Date().toLocaleString("en-GB", { timeZone: process.env.VUE_APP_TZ })
      this.project.created_at_front = date
      console.log(`date -> ${date}`)
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-2").then(result => {
          if (result && this.productsProject != null) {
            resolve(true)
          } else {
            reject("correct all values");
            this.scrollUp();
          }
        })
      })
    },
    async validateStep3() {
      this.dropzoneOptions.headers = await this.headers()
      return new Promise((resolve, reject) => {
        if (this.isFormValid || this.productsProject[0] != 4) {
          this.$validator.validateAll("step-3").then(result => {
            if (result) {
              resolve(true);
            } else {
              reject("correct all values");
            }
          });
        } else {
          reject("correct all values");
          this.scrollUp();
          this.handleFormFailure();
        }
      })
    },
    validateStep4() {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll("step-4").then(result => {
          this.garbage = result
          this.popupActive = false;
          this.percentajeForm = 100
        })
        this.garbage = reject
      })
    },
    resetForm() {
        this.contract_id = null
        this.productsProject.splice(0, 1)
        this.businessUnit = ''
    },
    createProject() {
      this.showLoader = true
      let user_id = this.userInfo.user.id
      let current_user = user_id
      let path = "/api/v1/projects"
      this.project.authorize_requesting_documents = this.contact_candidate


      // NOTE: Si el tipo de proyecto es visita
      if ([13, 6, 12, 15, 18].includes(this.productsProject[0])) {
        this.project.virtual = this.projectIsVirtual
      } else {
        delete this.project.virtual
      }

      if (this.businessUnit) {
          this.project.business_unit_id = this.businessUnit
      }

      if (!this.project_create) {
        let url = this.url() + path
        let creation_request = {}
        if (this.productsProject[0] == 4) {
          creation_request = { project: this.project, current_user: current_user, contract_id: this.contract_id, project_type_id: this.productsProject[0], files: this.files, poligraph: this.poligraph }
        } else {
          creation_request = { project: this.project, current_user: current_user, contract_id: this.contract_id, project_type_id: this.productsProject[0], files: this.files }
        }
        this.requestPost(url, creation_request).then(response => {
          this.project_create = true;
          let message = ""
          if (response.data.response.success == true) {
            this.project_id_create = response.data.response.data.project.id
            this.getProjectsByTypeEvent() // update first column events traceability when create project
            this.setDefaultFiles()
            this.clearInputsFiles()
            message = "Datos guardados"
            this.formComplete()
            this.path = "/api/v1/projects/fast_tracking?page="+1+"&limit="+7+"&query="+''
            this.requestGetWithoutContent(this.url()+this.path, {}).then(response => {
              if(response.data.success == true){
                this.projects = response.data.projects
                this.projectsCount = Math.ceil(response.data.projects_count/7)
              }else{
                console.log("error -> " + response.data.message);
              }
            }, (err) => {
              console.log('err users -> ' +  JSON.stringify(err))
            })
          } else {
            this.percentajeForm = 100
            message = response.data.response
            this.project_create = false
          }
          this.resetForm()
          this.showLoader = false
          this.$vs.notify({
            title: 'Mensaje de Globalwork',
            text: message,
            color: 'primary',
            position: 'bottom-left'
          })
        }, (err) => {
          this.resetForm()
          this.formComplete()
          this.showLoader = false
          this.garbage = err
          this.percentajeForm = 0

          this.$vs.notify({
            title: 'Mensaje de Globalwork',
            text: 'Se ha presentado un error en nuestros servidores. Por favor intenta nuevamente.',
            color: 'danger',
            position: 'bottom-left'
          })
        })
      }
      this.errors.clear();
    },
    getProjectsByTypeEvent() { // update first column events traceability when create project
      const eventType = { id: 0, name: 'request_process' }
      this.requestGetWithoutContent(`${this.url()}api/v1/projects/projects_by_event_type?user_id=${this.userInfo.user.id}&event_type=${eventType.id}&per_page=${this.perPage}&page=${this.page}`, {}).then((response) => {
        this.changeColumnEvent({ event_type: eventType.name, data: response.data })
      })
    },
    formComplete() {
      this.popupActivePopup = false;
      this.$refs.wizard.reset()
      this.project = {}
      let element = document.getElementById('inputCity')
      if (element != null) element.value = ''
      this.project_create = false
      this.$router.push('/')
    },
    onlyText(event) {
      let regex = null
      if (this.project.person_id_type != undefined && this.project.person_id_type > 2) {
        regex = new RegExp("^[a-zA-Z0-9]+$")
      } else {
        regex = new RegExp("^[0-9]+$")
      }
      let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key) || (this.project.person_id && this.project.person_id.length >= 15)) {
        event.preventDefault();
        return false;
      }
    },
    containsSpecialChars(input) {
      const specialCharsRegex = /[-!@#$%^&*(),.?":{}|<>/¿;+= ]/;
      return specialCharsRegex.test(input);
    },
    onPaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('text');
      const updatedValue = (this.project.person_id || '').substr(0, 15) + pastedData;
      if (this.containsSpecialChars(updatedValue) || updatedValue.length > 15) {
        event.preventDefault();
        return false;
      }
    },
    regExpressionText(event) {
      let regex = new RegExp("^[a-zA-ZÀ-ÿ ]")
      let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    regExpressionTextNumbers(event) {
      let regex = new RegExp("^[a-zA-ZÀ-ÿ0-9+\\s!\"#$%&'()*+,-./:;=¡¿?@[\\]^_`{|}~]*$")
      let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
        event.preventDefault();
        return false;
      }
    },
    validateFileType(file) {
      let files = []
      files.push(file)
      let typeAllowFiles = ["image/png", "image/jpg", "image/jpeg", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ]
      let result = this.validateMimetypes(files, typeAllowFiles)
      if (!result) {
        this.$refs.myVueDropzoneFiles.removeFile(file)
      }
    },
    async send_form(project) {
      if (this.productsProject[0] == 16) {
        const result = await this.requestGet(thir.urlPdfGenerator() + '/send_form', {
          project_id: project
        })
      }
    },
    notifyUpload() {
      this.$vs.notify({
        title: 'Mensaje de Globalwork',
        text: 'El archivo se ha subido correctamente',
        color: 'primary',
        position: 'bottom-left'
      })

    },
    setAuthorizeContact() {
      let contact_candidate = window.localStorage.getItem('contact_candidate')
      if (contact_candidate == "true") {
        this.contact_candidate = true
      } else {
        console.log(contact_candidate)
      }
    },
    async getCountries() {
      const axios = require('axios')
      try {
        let url = this.url() + 'api/v1/countries'
        let result = await this.requestGetWithoutContent(url, {})
        this.countries = result.data
        this.country_id = 1
      } catch (error) {
        console.log(error)
      }
    },
    async getCities() {
      try {
        let url = this.url() + `api/v1/cities/?country_id=${this.country_id}`
        let result = await this.requestGetWithoutContent(url, {})
        this.cities = result.data
      } catch (error) {
        console.log(error)
      }
    },
    deleteText() {
      if (this.city.length > 0) {
        this.city = this.city.slice(0, -1)
        console.log('Lo que queda: ', this.city)
      }
      if (typeof(this.city) == "number") {
        this.city = ""
      }
    },
    setDefaultFiles() {
      this.files = this.filesTemplate
    },
    toggleAccordion(index, mustDisplay) {
      for (let i = 0; i <= 4; i++) {
        const content = document.getElementById(`content${i}`);
        const chevron = document.getElementById(`chevron${i}`);
        if (i === index) {
          if (mustDisplay) {
              chevron.textContent = 'expand_less';
              content.style.display = 'block';
          } else {
              chevron.textContent = chevron.textContent === 'expand_less' ? 'expand_more' : 'expand_less';
              content.style.display = content.style.display === 'none' ? 'block' : 'none';
          }
        } else {
          chevron.textContent = 'expand_more';
          content.style.display = 'none';
        }
      }
    },
    noFilesAdded(type_file){
      let files = this.files.filter(file => file.id === type_file )
      return files.every(file => file.content == null)
    },
    resetChevrons() {
      for (let i = 0; i <= 4; i++) {
        const chevron = document.getElementById(`chevron${i}`);
        chevron.textContent = 'expand_more';
      }
    },
    resetAccordions() {
      for (let i = 0; i <= 4; i++) {
        const content = document.getElementById(`content${i}`);
        content.style.display = 'none';
      }
    }
  },
  mounted() {
    this.project_create = false;
    this.setAuthorizeContact()
    this.setDefaultFiles()
    this.resetChevrons()
    this.resetAccordions()
    this.toggleAccordion()
    this.getBusinessUnits()
  },
  created() {
    this.getCountries()
  },
  watch: {
    country_id() {
      this.getCities()
    },
    assignment_types: function() {
      if (this.assignment_types != null) {
        var assignments_attributtes = []
        this.assignment_types.forEach(assignment_type => {
          assignment_type.assignment_types.forEach(at => {
            assignments_attributtes.push({ "id": at })
          });
          assignments_attributtes.push({ "project_type_id": assignment_type.project_type_id })
        });
        this.assignments = assignments_attributtes
      }
    },
    type_file(val) {
      if (this.dropzoneOptions.url.includes('type_file')) {
        let newUrl = this.dropzoneOptions.url.replace(this.dropzoneOptions.url.split('&')[1], `type_file=${val}`)
        this.$refs.myVueDropzoneFiles.options.url = newUrl
        this.$refs.myVueDropzoneFiles.dropzone.options.url = newUrl
        this.dropzoneOptions.url = newUrl
      } else {
        this.dropzoneOptions.url += `&type_file=${val}`
      }
    },
    'project.person_id_type': function (newValue) {
      if (newValue < 2) {
        this.$nextTick(() => {
        this.$validator.validate('project.person_id');
        });
      }
    },
    productsProject(newValue) {
      if ([13, 6, 12, 15, 18].includes(newValue ? newValue[0] : null)) {
        this.projectIsVirtual = null;
      }
    }
  },
  components: {
    vSelect,
    FormWizard,
    TabContent,
    Datepicker,
    vueDropzone: vue2Dropzone,
    'products': Products,
    'PoligraphyCards': PoligraphyCards,
    Select,
    Loader
  },
  computed: {
    ...mapGetters(['userInfo'],['fastTrackingProjects', 'fastTrackingProjectsCount']),

    projects:{
      get(){
        return this.$store.state.fastTrackingProjects
      },
      set(val){
        this.$store.dispatch("updateFastTrackingProjects", val);
      }
    },
    projectsCount:{
      get(){
        return this.$store.state.fastTrackingProjectsCount
      },
      set(val){
        this.$store.dispatch("updateFastTrackingProjectsCount", val);
      }
    },

    /** Ocultar data crédito en estos tipos de proceso */
    hideDatacredit() {
      if (this.productsProject && [3, 19].includes(this.productsProject[0])) {
        this.$set(this.project, 'credit_validation', true)
        return true
      }
      return false
    },

    popupActivePopup: {
      get() {
        return this.popupActive
      },
      set(val) {
        this.$emit('update:popupActive', val)
      }
    }
  }
}
</script>

<style scoped>
.title-bar{
  border-radius: 8px;
  background: #F2EDFC;
  padding: 10px;
}
.form-labels{
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: 500;
  color: #3C1A4E;
}
.custom-title{
  color:#5F008F;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}

.custom-title.subtitle{
  color: #3C1A4E;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
}

.new-feature {
  color: rgb(95, 0, 145);
  font-size: 20px;
  font-weight: 600;
}

.read-more {
  margin-left: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.select {
  height: 38px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: none;
  color: inherit;
  font-size: 1rem;
  padding: 5px;
  border-radius: 4px;
  -webkit-appearance: textfield;
  background-color: white;
}

.files-candiate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.list-documents {
  width: 50%;
}

.item-document {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 32px;
}

.item-icon {
  vertical-align: sub;
  font-size: 15px;
}

.color-purple {
  color: #5F008F;
}

.color-green {
  color: #00AF50;
}

.icon-add {
  color: #5F008F;
  padding: 5px 7px !important;
  margin: 0px 5px !important;
  height: 100%;
}

.icon-add:hover {
  border-radius: 5px;
  height: 100%;
}

.vs-list--slot {
  position: relative;
  right: 200%;
}

.loader-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.accordion-header {
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    padding: 5px;
}

.accordion-content {
  display: none;
  border: none;
  /* padding: 10px; */
  margin: 0 15px;
}

.filter-input {
  width: 100%;
}

.extension-error{
  color: #3C1A4E;
  margin: 7px 20px 25px;
  font-weight: 600;
}

.extension-message{
  text-align: center;
}

.max-upload {
  color: #3C1A4E;
  font-size: 10px;
}

.added-file{
  padding: 5px;
}

.text-size{
  font-size: 13px;
}

.column {
  width: 45%;
}

.column-2 {
  width: 45%;
  align-content: left;
}

.no-added-files {
  font-size: 12px;
  color: #3C1A4E;
  padding: 5px;
}


</style>
