var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-white py-2 sm:py-2" }, [
    _c("div", { staticClass: "mx-auto max-w-7xl px-6 lg:px-8" }, [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass:
            "mx-auto mt-10 grid max-w-l grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-6 sm:pt-6 lg:mx-0 lg:max-w-none lg:grid-cols-3",
        },
        _vm._l(_vm.poligraphTypes, function (poligraph) {
          return _c(
            "article",
            {
              key: poligraph.nick,
              staticClass:
                "flex max-w-xl flex-col items-start justify-between poli-cards",
            },
            [
              _c("div", { staticClass: "group relative" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c("vs-radio", {
                      attrs: {
                        "vs-name": "poligraplyType",
                        "vs-value": poligraph.nick,
                      },
                      model: {
                        value: _vm.poligraphyType,
                        callback: function ($$v) {
                          _vm.poligraphyType = $$v
                        },
                        expression: "poligraphyType",
                      },
                    }),
                    _c(
                      "h3",
                      {
                        staticClass:
                          "mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600",
                        staticStyle: { "padding-bottom": "7px" },
                      },
                      [
                        _c("a", [
                          _vm._v(
                            "\n                " +
                              _vm._s(poligraph.title) +
                              "\n              "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "mt-5 line-clamp-3 text-sm leading-6 text-gray-600",
                  },
                  [_vm._v(_vm._s(poligraph.description))]
                ),
              ]),
              _c(
                "div",
                { staticClass: "relative mt-8 flex items-center gap-x-4" },
                [
                  _c("img", {
                    staticClass: "h-3 w-3 rounded-full bg-gray-50",
                    attrs: { src: poligraph.author.imageUrl, alt: "" },
                  }),
                  _c(
                    "div",
                    { staticClass: "flex items-center gap-x-4 text-xs" },
                    [
                      _c("p", { staticClass: "text-gray-500" }, [
                        _c("strong", [
                          _vm._v(
                            "Tiempo aproximado: " + _vm._s(poligraph.timeAprox)
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _vm.poligraphyType == "pre-emple"
        ? _c(
            "div",
            {
              staticClass: "relative mt-8 flex items-center gap-x-4 title-bar",
            },
            [
              _c("img", {
                staticClass: "h-6 w-6 rounded-full bg-gray-50",
                staticStyle: { "margin-left": "10px" },
                attrs: {
                  src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Danger-Circle.png",
                  alt: "",
                },
              }),
              _c(
                "p",
                {
                  staticClass: "mt-2 text-md leading-8 text-gray-600 poli-text",
                },
                [
                  _vm._v(
                    "Recuerda que dependemos de la respuesta oportuna del candidato para comenzar con el servicio.\n        Al crear este proceso buscaremos la asignación de nuestros profesionales de manera inmediata. Al momento de ser asignado se le enviaran las recomendaciones\n        a la persona que realizará la prueba."
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      this.poligraphyType == null && this.formFailed
        ? _c(
            "div",
            {
              staticClass:
                "mx-auto grid max-w-l grid-cols-1 gap-x-8 border-t border-gray-200 pt-5 sm:mt-3 sm:pt-3 lg:mx-0",
            },
            [
              _c("span", { staticClass: "text-danger" }, [
                _vm._v("Selecciona al menos una poligrafía para continuar"),
              ]),
            ]
          )
        : _vm._e(),
      _vm.poligraphyType == "ruti"
        ? _c(
            "div",
            {
              staticClass:
                "mx-auto mt-10 grid max-w-l grid-cols-1 gap-x-8 border-t border-gray-200 pt-5 sm:mt-3 sm:pt-3 lg:mx-0",
            },
            [
              _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                _vm._v(
                  "Cuéntanos brevemente cual es el motivo de la prueba (Después te solicitaremos más información especifica al respecto)."
                ),
              ]),
              _c(
                "div",
                [
                  _c("vs-textarea", {
                    model: {
                      value: _vm.poligraphReason,
                      callback: function ($$v) {
                        _vm.poligraphReason = $$v
                      },
                      expression: "poligraphReason",
                    },
                  }),
                ],
                1
              ),
              (this.poligraphReason == null || this.poligraphReason == "") &&
              this.formFailed &&
              this.poligraphyType != "pre-emple"
                ? _c("div", { staticClass: "mb-2" }, [
                    _c("p", { staticClass: "text-danger" }, [
                      _vm._v("Escribe algo para continuar"),
                    ]),
                  ])
                : _vm._e(),
              _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                _vm._v(
                  "Sugiere una posible fecha de disponibilidad de los colaboradores"
                ),
              ]),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c("img", {
                    staticClass: "h-6 w-6 bg-gray-50 calendar-icon",
                    staticStyle: { "z-index": "1" },
                    attrs: {
                      src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Group.png",
                      alt: "",
                    },
                  }),
                  _c("vue-flatpickr", {
                    staticStyle: { position: "absolute", "z-index": "0" },
                    attrs: { id: "poliDate", config: _vm.flatpickrOptions },
                    model: {
                      value: _vm.poligraphDate,
                      callback: function ($$v) {
                        _vm.poligraphDate = $$v
                      },
                      expression: "poligraphDate",
                    },
                  }),
                  _c("img", {
                    staticStyle: {
                      "margin-left": "210px",
                      "margin-top": "5px",
                    },
                    style: {
                      opacity: _vm.opacityState,
                      cursor: _vm.poligraphDateSecond
                        ? "not-allowed"
                        : "pointer",
                    },
                    attrs: {
                      src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/M.png",
                      alt: "",
                    },
                    on: { click: _vm.toggleContent },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isContentVisible,
                      expression: "isContentVisible",
                    },
                  ],
                  staticClass: "expandable-content mt-5",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mt-3 mb-5",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _c("img", {
                        staticClass: "h-6 w-6 bg-gray-50 calendar-icon",
                        staticStyle: { "z-index": "1" },
                        attrs: {
                          src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Group.png",
                          alt: "",
                        },
                      }),
                      _c("vue-flatpickr", {
                        staticStyle: { position: "absolute" },
                        attrs: {
                          id: "poliDateSecond",
                          config: _vm.flatpickrOptions,
                        },
                        model: {
                          value: _vm.poligraphDateSecond,
                          callback: function ($$v) {
                            _vm.poligraphDateSecond = $$v
                          },
                          expression: "poligraphDateSecond",
                        },
                      }),
                      _c("img", {
                        staticStyle: {
                          "margin-left": "210px",
                          "margin-top": "5px",
                        },
                        attrs: {
                          src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Delete.png",
                          alt: "",
                        },
                        on: { click: _vm.deletePoligraphDateSecond },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._m(1),
            ]
          )
        : _vm._e(),
      _vm.poligraphyType == "especi"
        ? _c(
            "div",
            {
              staticClass:
                "mx-auto mt-10 grid max-w-l grid-cols-1 gap-x-8 border-t border-gray-200 pt-5 sm:mt-3 sm:pt-3 lg:mx-0",
            },
            [
              _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                _vm._v(
                  "Cuéntanos brevemente cual es el motivo de la prueba (Después te solicitaremos más información especifica al respecto)."
                ),
              ]),
              _c(
                "div",
                [
                  _c("vs-textarea", {
                    model: {
                      value: _vm.poligraphReason,
                      callback: function ($$v) {
                        _vm.poligraphReason = $$v
                      },
                      expression: "poligraphReason",
                    },
                  }),
                ],
                1
              ),
              (this.poligraphReason == null || this.poligraphReason == "") &&
              this.formFailed &&
              this.poligraphyType != "pre-emple"
                ? _c("div", { staticClass: "mb-2" }, [
                    _c("p", { staticClass: "text-danger" }, [
                      _vm._v("Escribe algo para continuar"),
                    ]),
                  ])
                : _vm._e(),
              _c("p", { staticStyle: { "margin-bottom": "10px" } }, [
                _vm._v(
                  "Sugiere una posible fecha de disponibilidad de los colaboradores"
                ),
              ]),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c("img", {
                    staticClass: "h-6 w-6 bg-gray-50 calendar-icon",
                    staticStyle: { "z-index": "1" },
                    attrs: {
                      src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Group.png",
                      alt: "",
                    },
                  }),
                  _c("vue-flatpickr", {
                    staticStyle: { position: "absolute", "z-index": "0" },
                    attrs: { id: "poliDate", config: _vm.flatpickrOptions },
                    model: {
                      value: _vm.poligraphDate,
                      callback: function ($$v) {
                        _vm.poligraphDate = $$v
                      },
                      expression: "poligraphDate",
                    },
                  }),
                  _c("img", {
                    staticStyle: {
                      "margin-left": "210px",
                      "margin-top": "5px",
                    },
                    style: {
                      opacity: _vm.opacityState,
                      cursor: _vm.poligraphDateSecond
                        ? "not-allowed"
                        : "pointer",
                    },
                    attrs: {
                      src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/M.png",
                      alt: "",
                    },
                    on: { click: _vm.toggleContent },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isContentVisible,
                      expression: "isContentVisible",
                    },
                  ],
                  staticClass: "expandable-content mt-5",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "mt-3 mb-5",
                      staticStyle: { display: "flex" },
                    },
                    [
                      _c("img", {
                        staticClass: "h-6 w-6 bg-gray-50 calendar-icon",
                        staticStyle: { "z-index": "1" },
                        attrs: {
                          src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Group.png",
                          alt: "",
                        },
                      }),
                      _c("vue-flatpickr", {
                        staticStyle: { position: "absolute" },
                        attrs: {
                          id: "poliDateSecond",
                          config: _vm.flatpickrOptions,
                        },
                        model: {
                          value: _vm.poligraphDateSecond,
                          callback: function ($$v) {
                            _vm.poligraphDateSecond = $$v
                          },
                          expression: "poligraphDateSecond",
                        },
                      }),
                      _c("img", {
                        staticStyle: {
                          "margin-left": "210px",
                          "margin-top": "5px",
                        },
                        attrs: {
                          src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Delete.png",
                          alt: "",
                        },
                        on: { click: _vm.deletePoligraphDateSecond },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._m(2),
            ]
          )
        : _vm._e(),
      this.poligraphyType != "especi" && this.poligraphyType
        ? _c("div", { staticClass: "pt-6 text-lg leading-8 text-gray-600" }, [
            _c("p", { staticStyle: { "padding-top": "25px" } }, [
              _vm._v("Selecciona el formato de la poligrafía"),
            ]),
            _c(
              "div",
              { staticClass: "relative mt-6 flex items-center gap-x-4" },
              [
                _c(
                  "div",
                  {
                    staticClass: "relative mt-2 flex items-center gap-x-4 mr-4",
                    staticStyle: { color: "#634871" },
                  },
                  [
                    _c("vs-radio", {
                      attrs: { "vs-value": "false" },
                      model: {
                        value: _vm.poligraphVirtual,
                        callback: function ($$v) {
                          _vm.poligraphVirtual = $$v
                        },
                        expression: "poligraphVirtual",
                      },
                    }),
                    _c("p", [_vm._v("Presencial (Prueba de poligrafo)")]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "relative mt-2 flex items-center gap-x-4",
                    staticStyle: { color: "#634871" },
                  },
                  [
                    _c("vs-radio", {
                      attrs: { "vs-value": "true" },
                      model: {
                        value: _vm.poligraphVirtual,
                        callback: function ($$v) {
                          _vm.poligraphVirtual = $$v
                        },
                        expression: "poligraphVirtual",
                      },
                    }),
                    _c("p", [_vm._v("Virtual (VSA - Voice Analysis System)")]),
                  ],
                  1
                ),
              ]
            ),
            this.poligraphVirtual == null &&
            this.formFailed &&
            this.poligraphyType != "especi"
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mx-auto grid max-w-l grid-cols-1 gap-x-8 border-t border-gray-200 pt-5 sm:mt-3 sm:pt-3 lg:mx-0",
                  },
                  [
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        "Selecciona al menos una poligrafía para continuar"
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      this.poligraphyType == "especi"
        ? _c(
            "div",
            {
              staticClass: "relative mt-4 flex items-center gap-x-4 pl-5 pt-3",
            },
            [
              _c("img", {
                staticClass: "h-6 w-6 rounded-full bg-gray-50",
                attrs: {
                  src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Danger-Circle.png",
                  alt: "",
                },
              }),
              _c(
                "p",
                {
                  staticClass: "mt-2 text-md leading-8 text-gray-600 poli-text",
                },
                [
                  _vm._v(
                    "El servicio de poligrafía específica solo se presta de manera presencial (Prueba de poligrafo)"
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mx-auto lg:mx-0 title-bar" }, [
      _c(
        "p",
        { staticClass: "mt-2 text-lg leading-8 text-gray-600 poli-text" },
        [_vm._v("Seleccionaste poligrafía")]
      ),
      _c(
        "h2",
        {
          staticClass:
            "text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl titlep",
        },
        [_vm._v("¿Qué clase de poligrafía requieres?")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "relative mt-8 flex items-center gap-x-4 title-bar" },
      [
        _c("img", {
          staticClass: "h-6 w-6 rounded-full bg-gray-50",
          staticStyle: { "margin-left": "10px" },
          attrs: {
            src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Danger-Circle.png",
            alt: "",
          },
        }),
        _c(
          "p",
          { staticClass: "mt-2 text-md leading-8 text-gray-600 poli-text" },
          [
            _c("strong", [
              _vm._v(
                "Esto no garantiza que los cupos se reserven para esta fecha, pero buscaremos las fechas más cercanas."
              ),
            ]),
            _vm._v(
              " Al momento de\n           ser asignado le enviaremos las recomendaciones a la persona de tu empresa encargada del proceso."
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "relative mt-8 flex items-center gap-x-4 title-bar" },
      [
        _c("img", {
          staticClass: "h-6 w-6 rounded-full bg-gray-50",
          staticStyle: { "margin-left": "10px" },
          attrs: {
            src: "https://globalwork-production.s3.amazonaws.com/Pdf/mha-clients-icons/Danger-Circle.png",
            alt: "",
          },
        }),
        _c(
          "p",
          { staticClass: "mt-2 text-md leading-8 text-gray-600 poli-text" },
          [
            _c("strong", [
              _vm._v(
                "Esto no garantiza que los cupos se reserven para esta fecha, pero buscaremos las fechas más cercanas."
              ),
            ]),
            _vm._v(
              " Al momento de\n           ser asignado le enviaremos las recomendaciones a la persona de tu empresa encargada del proceso."
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }